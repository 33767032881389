@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alexandria:wght@500&family=Cairo:wght@300;400&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@500&family=Cairo:wght@400;600&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Alexandria', sans-serif;
font-family: 'Cairo', sans-serif;

}
body,.general{
  font-family: 'Alexandria', sans-serif;
  font-family: 'Cairo', sans-serif;
  
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  font-family: 'Alexandria', sans-serif;
font-family: 'Cairo', sans-serif;
}
/**/
.loading-dev{
  width: 100%;
  height: 100vh;
  background-color: lightgrey;
  color: black;
  font-size: 30px;
}
/*login page*/
.login{
  background-color: #f4f6f7;
  height: 100vh;
}
.allThings{
  text-align: center;
  margin-top: 100px;
}
.allThings img{
width: 100%;
}
.med{
 color: #2891cd;
 font-weight: bold;
 margin-bottom: 30px;
}
.med h6{
font-size: 12px;
}
.allThings input{
padding: 10px 15px;
border:0;
border-radius: 15px;
/*border: 1px solid #e1e0e0;*/
}
.allThings button{
margin-top: 20px;
background-color: #2891cd;
border:0;
border-radius: 15px;
color:white;
font-weight: bold;
width: 100%;
padding: 9px 12px;
  }
  .allThings button:hover{

opacity: 0.9;
  }
  .general{
    display: flex;
 width: 100%;
 
  
  }
  .general .second{
width: 100%;

  }
  .mapcont{
    overflow: auto;
  }
  .xxx{
    background-color: #f5f7f7 !important;
  }
  .active{
    color: #2891cd;
  }
  .inactive{
    color: black;
    
  }
  .btnclick{
    border:0;
    background-color: #f4f6f7;

    color: #7a7b7b !important;
    font-weight: 500;
  }
  .btnclick:hover{
color:#2891cd !important
  }

  .user-menu{
   color:#2891cd;
  }
  .logout-btn{
    border:0;
    background-color: white;
    color: #7a7b7b;
   width: 100%;
  }
  .ul-navbar{
    list-style: none;
    display: block;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
   margin:10px 3px 10px 3px
  }
  .ul-navbar li{
    list-style: none;
    display: block;
    margin: 10px;
  }
  .parentTable-customer-support{
    border: 1px solid lightgray;
    overflow: auto;
    max-height: 550px;
    background-color: white;
    border-radius: 6px;
    margin: 30px;
    margin-top: 0;
   color: #272828;
   padding: 7px;
  }
  thead {
    top: 0;
    position: sticky;
    background: white;
    color: black;
 }
  .parentTable-customer-support tbody{

  }
  .parentTable2{
    overflow: auto; 

    background-color: white;
    border-radius: 6px;
    margin:5px;
    margin-top: 0;
color: #272828;
padding: 7px;

  }
  .parentTable3{
    overflow: auto;
    cursor:default !important;
    background-color: white;
    border-radius: 6px;
    margin:5px;
    margin-top: 0;
color: #272828;
padding: 7px;

  }
  .parentTable-customer-support table{
 
    color: #272828;
    border-radius: 6px;
    background-color: white;
    
  }
  .parentTable3 table{
 cursor:default !important;
    color: #272828;
    border-radius: 6px;
    background-color: white;
 
  }
  .parentTable3 table tr{
    cursor:default !important;
    
       
     }
  .parentTable2 table{
 
    color: #272828;
    border-radius: 6px;
    background-color: white;
    padding: 5px;
  }
  .parentTable3 table{
 
    color: #272828;
    border-radius: 6px;
    background-color: white;
    padding: 5px;
  }
  .table td, .table th{
    color: #272828 !important;
  }

  .page-header{
    margin: -5px 30px -5px 30px;
    font-size: 20px;
  }
  .addaxisinfosec{
    margin-top: -25px;
  
  }
  .pageArrow{
    border: 0;
    margin: 10px;
    border-radius: 7px;
   
  }
  .show-span{
    color: #7a7b7b;
    font-size: 15px;
  }
  .show-select{
   
padding: 3px;
border-radius: 4px;
border: 1px solid gray;
color:#7a7b7b
  }
  .table tr{
    cursor: pointer;
  }
  .table tr th{
  
  }
  .Info-all{
    background-color: white;
    padding:17px 30px;
    margin: 15px 20px 10px 20px;
    border-radius: 15px;
  }
  .firstsec{
    margin: 25px 20px 10px 20px;
  }
  .secondsec{
    margin: 15px 20px 20px 20px;
  }
  .Info-all h6{
    margin:15px;
  
  }
  .Info-all h3{
    margin:10px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
  }
  .secondsec h3{
    margin:10px;
    border-bottom: 0;
    padding-bottom: 0px;
  }
  .value-info{
    font-weight: bold;

  }
  /*404*/
#notfound{position:relative;height:100vh}
#notfound .notfound{position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.notfound{max-width:520px;width:100%;line-height:1.4;text-align:center}
.notfound .notfound-404{position:relative;height:240px}
.notfound .notfound-404 h1{font-family:montserrat,sans-serif;position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);font-size:252px;font-weight:900;margin:0;color:#262626;text-transform:uppercase;letter-spacing:-40px;margin-left:-20px}.notfound .notfound-404 h1>span{text-shadow:-8px 0 0 #fff}
.notfound .notfound-404 h3{font-family:cabin,sans-serif;position:relative;font-size:16px;font-weight:700;text-transform:uppercase;color:#262626;margin:0;letter-spacing:3px;padding-left:6px}.notfound h1{font-family:cabin,sans-serif;font-size:20px;font-weight:400;text-transform:uppercase;color:#000;margin-top:0;margin-bottom:25px}@media only screen and (max-width:767px){.notfound .notfound-404{height:200px}.notfound .notfound-404 h1{font-size:200px}}@media only screen and (max-width:480px){.notfound .notfound-404{height:162px}.notfound .notfound-404 h1{font-size:162px;height:150px;line-height:162px}.notfound h2{font-size:16px}}
/***/

.head-filter{
  margin-top:10px;
  margin-bottom: 10px;
}
.optionContainer{
  height:80px !important;
  overflow-y: scroll !important; 
}
.editBtn{
  background-color: white;
  border:0;
  color:gray;
}
.delBtn{
  background-color: white;
  border:0;
  color:gray;

}
.iconGlob{
  font-size: 15px !important;

}
.filter-hover:hover{
  background-color: lightgray !important;
}
/*******************/
.search-input{
  width:400px !important;
 
}
.input-group-parent button{
  background-color: #8e9aa5 !important;
 
  border:1px solid #8e9aa5 !important; 
}
@media only screen and (max-width: 800px) {
  .search-input{
    width:200px  !important;
    margin:3px !important;
  }
  .tabs-parent{
    width:75%  !important;
  }
}
.parent-search-input{
  color: #272828;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 6px;
  border-radius: 6px;
  margin: 0px 30px 0 30px;
}
.parent-search-input div{
margin: 14px;
margin-bottom: 5px;
}
.parent-search-input div:first-of-type{
  margin: 14px 0 5px 0;

    }
     /*above table*/
  .table-features-section{
    color: #272828;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 6px;
    border-radius: 6px;
    margin: -15px 30px 0px 30px;
  }
  .table-data-more-analysis{
  margin-top: 20px;
  }
  .pagination-limit-table-info-part{
    margin-bottom: -5px;
    margin-top: 5px;
  
  }
  .filter-parent{
   margin: -1px 10px  -5px 10px;

  }
  .filter-open-btn{
margin-bottom: 5px !important;

  }
  .datepicker-custom {
    width: 190px ;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 6px;
  }
  .DatePickerLabel{
    margin: 10px;
  }
  .datepicker-filter-btn{
    margin: 15px;
    padding: 6px 11px;
    background-color: rgb(40, 174, 40);
    border:0;
    color:white;
    border-radius: 10px;
  }
  .parentEachDatePicker{
    display: block;
  }
  .react-datepicker-popper {
    width:330px !important
  }
  .parent-fare-media-info{
    padding: 5px 30px 13px 30px;
  }
  .fare-media-info-container{
    padding: 10px 0 0px 0;
  }
  .fare-media-info-card{

    border: 1px solid lightgray !important;
  }
  .fare-media-info-card-text{
    padding: 4px;
    border-bottom: 1px solid #F5F5F8 !important;
  }
   .no-fare-media-design{
    padding: 15px;
    text-align: center;
    margin: 3px 30px 10px 30px;
    /*border: 1px solid gray;*/
    border-radius: 5px;
    background-color: #F5F5F8;
  }
  .trans-types-tabs-parent{

  }
  .trans-types-tabs-child{

  }
  .trans-types-tabs-child{
   
  }