@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alexandria:wght@500&family=Cairo:wght@300;400&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@500&family=Cairo:wght@400;600&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Alexandria', sans-serif;
font-family: 'Cairo', sans-serif;

}
body,.dev-contain-all-page-content{
  font-family: 'Alexandria', sans-serif;
  font-family: 'Cairo', sans-serif;
  
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  font-family: 'Alexandria', sans-serif;
font-family: 'Cairo', sans-serif;
}
/**/
.loading-dev{
  width: 100%;
  height: 100vh;
  background-color: lightgrey;
  color: black;
  font-size: 30px;
}
/*login page*/
.login{
  background-color: #f4f6f7;
  height: 100vh;
}
.allThings{
  text-align: center;
  margin-top: 100px;
}
.allThings img{
width: 100%;
}
.med{
 color: #2891cd;
 font-weight: bold;
 margin-bottom: 30px;
}
.med h6{
font-size: 12px;
}
.allThings input{
padding: 10px 15px;
border:0;
border-radius: 15px;
/*border: 1px solid #e1e0e0;*/
}
.allThings button{
margin-top: 20px;
background-color: #2891cd;
border:0;
border-radius: 15px;
color:white;
font-weight: bold;
width: 100%;
padding: 9px 12px;
  }
  .allThings button:hover{

opacity: 0.9;
  }
  .dev-contain-all-page-content{
    display: flex;
    width: 100%;
    height: 100vh;
    flex-flow:column;
    flex:1 1 auto;
  }

  .mapcont{
    overflow: auto;
  }
  .xxx{
    background-color: #f5f7f7 !important;
  }
  .active{
    color: #2891cd;
  }
  .inactive{
    color: black;
    
  }
  .btnclick{
    border:0;
    background-color: #f4f6f7;

    color: #7a7b7b !important;
    font-weight: 500;
  }
  .btnclick:hover{
color:#2891cd !important
  }

  .user-menu{
   color:#2891cd;
  }
  .logout-btn{
    border:0;
    background-color: white;
    color: #7a7b7b;
   width: 100%;
  }
  .ul-navbar{
    list-style: none;
    display: block;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
   margin:10px 3px 10px 3px
  }
  .ul-navbar li{
    list-style: none;
    display: block;
    margin: 10px;
  }
  .Table-Wrapper{
  border-top:1px solid lightgray;
  border-bottom:1px solid lightgray;
overflow: auto !important ;
    background-color: white;
    border-radius: 6px;
    margin: 0px;
    margin-top: 0;
    color: #272828;
    padding: 7px;
   
  }

  .parentTable2{
    overflow: auto; 

    background-color: white;
    border-radius: 6px;
    margin:5px;
    margin-top: 0;
color: #272828;
padding: 7px;

  }
  .parentTable3{
    overflow: auto;
    cursor:default !important;
    background-color: white;
    border-radius: 6px;
    margin:5px;
    margin-top: 0;
color: #272828;
padding: 7px;

  }
  
  .parentTable3 table{
 cursor:default !important;
    color: #272828;
    border-radius: 6px;
    background-color: white;
 
  }
  .parentTable3 table tr{
    cursor:default !important;
    
       
     }
  .parentTable2 table{
 
    color: #272828;
    border-radius: 6px;
    background-color: white;
    padding: 5px;
  }
  .parentTable3 table{
 
    color: #272828;
    border-radius: 6px;
    background-color: white;
    padding: 5px;
  }
  .table td, .table th{
    color: #272828 !important;
  }
 
  .table-controller-sec{
    color: #272828;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    padding: 6px;
    border-radius: 6px;

    margin: 26px 30px 0px 30px;
   
  }
  .table-controller-sec div{

  }
  .table-controller-sec div:first-of-type{
    margin: 7px 0 0 0;

      }
  .header-of-subsystem-page{
    margin: -10px 30px -18px 30px;
    font-size: 25px;
  }
  
  .addaxisinfosec{
    margin-top: -25px;
  
  }
  .page-navigation-arrow{
    border: 0;
    margin: 10px;
    border-radius: 7px;
   padding:0px;
   background-color: white
  }
  .show-span{
    color: #7a7b7b;
    font-size: 15px;
  }
  .show-select{
   
padding: 0px;
border-radius: 8px;
border: 1px solid #9FA3B4;
color:#7a7b7b
  }
  .table tr{
    cursor: pointer;
  }
  .table tr th{
  
  }
  .Info-all{
    background-color: white;
    padding:17px 30px;
    margin: 15px 20px 10px 20px;
    border-radius: 15px;
  }
  .firstsec{
    margin: 25px 20px 10px 20px;
  }
  .secondsec{
    margin: 15px 20px 20px 20px;
  }
  .Info-all h6{
    margin:15px;
  
  }
  .Info-all h3{
    margin:10px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
  }
  .secondsec h3{
    margin:10px;
    border-bottom: 0;
    padding-bottom: 0px;
  }
  .value-info{
    font-weight: bold;

  }
  /*404*/
#notfound{position:relative;height:100vh}
#notfound .notfound{position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.notfound{max-width:520px;width:100%;line-height:1.4;text-align:center}
.notfound .notfound-404{position:relative;height:240px}
.notfound .notfound-404 h1{font-family:montserrat,sans-serif;position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);font-size:252px;font-weight:900;margin:0;color:#262626;text-transform:uppercase;letter-spacing:-40px;margin-left:-20px}.notfound .notfound-404 h1>span{text-shadow:-8px 0 0 #fff}
.notfound .notfound-404 h3{font-family:cabin,sans-serif;position:relative;font-size:16px;font-weight:700;text-transform:uppercase;color:#262626;margin:0;letter-spacing:3px;padding-left:6px}.notfound h1{font-family:cabin,sans-serif;font-size:20px;font-weight:400;text-transform:uppercase;color:#000;margin-top:0;margin-bottom:25px}@media only screen and (max-width:767px){.notfound .notfound-404{height:200px}.notfound .notfound-404 h1{font-size:200px}}@media only screen and (max-width:480px){.notfound .notfound-404{height:162px}.notfound .notfound-404 h1{font-size:162px;height:150px;line-height:162px}.notfound h2{font-size:16px}}
/***/

.head-filter{
  margin-top:10px;
  margin-bottom: 10px;
}
.optionContainer{
  height:80px !important;
  overflow-y: scroll !important; 
}
.editBtn{
  background-color: white;
  border:0;
  color:gray;
}
.delBtn{
  background-color: white;
  border:0;
  color:gray;

}
.iconGlob{
  font-size: 15px !important;

}
.filter-hover:hover{
  background-color: lightgray !important;
}
/**/
.editAndDeleteIcon{
  color:#B5B7BD;
  background: none !important;
}
.editAndDeleteIcon:hover{
  color:black;
}
.styleAddBtn{
  background-color: #0FA66D;
  border: 0;
  color:white;
  border-radius: 25px;
 font-weight: 600;
  padding: 10px 18px;
  position: absolute;
  
}
.updateBtnStyle{
color:white;
border:1px  solid #0FA66D;
padding:6px 15px;
background-color:#0FA66D;
border-radius: 16px;
margin: 6px;
}
.cancelBtnStyle{
  color:#262626;
  border:1px  solid lightgray;
  padding:6px 13px;
  background-color:white;
  border-radius: 16px;
  margin: 6px;
  }
  .inputStyle{
    display: block;
    padding: 10px;
    width:310px;
    border-radius: 6px;
    border:1px  solid lightgray;
    margin-top: 2px;
    margin-bottom: 10px;
  }
  .table-view-item-info{
width:45%;
border: 1px solid lightgray;
border-radius: 5px;

  }
  .name-and-actions-btns-sec{
    border-bottom: 1px solid lightgray;
    padding:22px;
    display: flex;
justify-content: space-between;
padding-bottom: 13px;
  }
  .title-item-sec{
font-weight: bold;
  }
  .actions-btns-sec{

  }
  .data-view-sec{
    padding:23px;
    background-color: #F7F7F8;
    padding-bottom: 10px;
  }
  .one-info{
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-start;
  }
  .title-of-one-info{
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
  }
  .value-of-one-info{
    width: 50%;
  }
  .parent-form{
    display: flex;
    justify-content: flex-start;
  }
  .parent-form div{
  padding: 10px;
  }
  .input-search{
position: relative;
  }
  .input-search input{
    padding: 9px 22px;
    border:1px solid #D4D3D3;
    border-radius: 8px;
    width:225px
  }
  .input-search button{
position: absolute;
background-color: #4B506D;
border-radius: 13px;
color:white;
padding: 2px 13px;
top:6px;
border: 1px solid #4B506D;
  }
  .clear-btn{
    background-color: white;
    border:1px solid lightgray;
    border-radius: 5px;
    padding: -20px -20px;
    margin-left: 10px;
    margin-right: 10px;
   
    color: gray;
    border-radius: 15px;
  }

  .inputStylePassword{
    padding: 10px;
    width:310px;
    border-radius: 6px;
    border:1px  solid lightgray;
    margin: -5px -10px 3px -10px;
  }
  .inputPasswordContainer{
    padding: 0;
    margin: 0;
    position: relative;
  }
  .visibIcon{
    position: absolute;
    
  }
  .inputStylePassword-in-view{
    width:200px;
    border:0;
    padding:0;

  }
  .styleAddBulkBtn{
    background-color: white;
    border:1px solid lightgray;
    color:#4B506D;
    border-radius: 25px;
   font-weight: 600;
    padding: 7px 22px;
    position: absolute;
  }
  .upload-style-btn-label{
    background-color: #0FA66D;
    border: 0;
    color:white;
    border-radius: 25px;
   font-weight: 600;
    padding: 10px 18px;
  }
  .download-style-btn{
    background-color: white;
    border:1px solid lightgray;
    color:#4B506D;
    border-radius: 25px;
   font-weight: 600;
    padding: 7px 22px;
    margin:10px
  }
  .availabe-org{
    display:flex;
    justify-content: center;
   /* background-color: #F6F7FF;*/
    margin: 12px;
    border-radius: 25px;
  }
  .bi-div{
    border:1px solid lightgray;
    border-radius: 25px;
  overflow: hidden;
  margin:12px
  }