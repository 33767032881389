html,
body,
body > div:first-of-type {
  height: 100%;
}

body {
  margin: 0;
  font-family: Manrope, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*******zoning Style*************/
@media only screen and (max-width: 900px) {
  .zoningSec{
    width:45% !important
  }
}
@media only screen and (max-width: 600px) {
.zoningSec{
  width:100% !important
}
}
.bi-btns:hover{
background-color: #F6F7FF !important;

border:0 !important
}